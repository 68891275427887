import request from '../utils/request'
import qs from 'qs'

/**
 * 获取用户信息并登录
 * @param accessLink
 * @returns {*}
 */
export function zwfwLogin(token){
    return request({
        url: 'zwfw/index.do',
        method: 'post',
        data: qs.stringify({token: token})
    })
}

/**
 * 获取token
 * @param accessLink
 * @returns {*}
 */
export function getToken(accessLink){
    return request({
        url: 'zwfw/getToken.do',
        method: 'post',
        data: qs.stringify({accessLink: accessLink})
    })
}

/**
 * 退出
 * @param accessLink
 * @returns {*}
 */
export function loginOut(){
    return request({
        url: 'zwfw/out.do',
        method: 'post'
    })
}